@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  padding-right: 0 !important;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.w-420px {
  width: calc(100% - 420px);
}

[data-headlessui-state='open'] {
  z-index: 30;
}

.lineheight-14 {
  line-height: 14px !important;
}

.lineheight-18 {
  line-height: 18px !important;
}

.bg-white-whiteSolid {
  background-color: #ffff;
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrow {
  -moz-appearance: textfield;
}

:root {
  --checkbox-border-default: #73828c;
  --checkbox-border-active: #25a9ef;
  --checkbox-border-disable-checked: #cbd2d9;
  --checkbox-border-disable-unchecked: #cbd2d9;
  --checkbox-background-default: #ffffff;
  --checkbox-background-active: #25a9ef;
  --checkbox-background-disable-checked: #cbd2d9;
  --checkbox-background-disable-unchecked: #f3f3f3;
  --checkbox-check-default: #ffffff;
  --checkbox-check-active: #ffffff;
  --checkbox-check-disable: #ffffff;
  --radio-border-default: #73828c;
  --radio-border-active: #25a9ef;
  --radio-border-disable-checked: #cbd2d9;
  --radio-border-disable-unchecked: #cbd2d9;
  --radio-background-default: #ffffff;
  --radio-background-active: #25a9ef;
  --radio-background-disable-checked: #cbd2d9;
  --radio-background-disable-unchecked: #ffffff;
  --toggle-background-active: #25a9ef;
  --toggle-background-inactive: #73828c;
  --toggle-background-disable: #cbd2d9;
  --toggle-circle-active: #ffffff;
  --toggle-circle-inactive: #ffffff;
  --toggle-circle-disable: #ffffff;
  --badge-background-default: #25a9ef;
  --badge-text-default: #ffffff;
  --badgeRequired-background-default: #eaedf0;
  --badgeRequired-text-default: #73828c;
  --button-primary-background-default: #144e91;
  --button-primary-background-hover: #0e3766;
  --button-primary-background-active: #06203f;
  --button-primary-background-disable: #f3f3f3;
  --button-primary-border-default: #144e91;
  --button-primary-border-hover: #0e3766;
  --button-primary-border-active: #06203f;
  --button-primary-border-disable: #cbd2d9;
  --button-primary-textAndIcon-default: #ffffff;
  --button-primary-textAndIcon-hover: #ffffff;
  --button-primary-textAndIcon-active: #ffffff;
  --button-primary-textAndIcon-disable: #8ea1ae;
  --button-secondary-background-default: #ffffff;
  --button-secondary-background-hover: #f0f5f9;
  --button-secondary-background-active: #edf9ff;
  --button-secondary-background-disable: #f3f3f3;
  --button-secondary-border-default: #144e91;
  --button-secondary-border-hover: #0e3766;
  --button-secondary-border-active: #06203f;
  --button-secondary-border-disable: #cbd2d9;
  --button-secondary-textAndIcon-default: #144e91;
  --button-secondary-textAndIcon-hover: #0e3766;
  --button-secondary-textAndIcon-active: #06203f;
  --button-secondary-textAndIcon-disable: #8ea1ae;
  --button-tertiary-background-default: #f0f5f9;
  --button-tertiary-background-hover: #edf9ff;
  --button-tertiary-background-active: #c9ecff;
  --button-tertiary-background-disable: #f3f3f3;
  --button-tertiary-border-default: #f0f5f9;
  --button-tertiary-border-hover: #edf9ff;
  --button-tertiary-border-active: #c9ecff;
  --button-tertiary-border-disable: #f3f3f3;
  --button-tertiary-textAndIcon-default: #144e91;
  --button-tertiary-textAndIcon-hover: #0e3766;
  --button-tertiary-textAndIcon-active: #06203f;
  --button-tertiary-textAndIcon-disable: #8ea1ae;
  --dropdownSwitcher-button-background-default: #144e91;
  --dropdownSwitcher-button-background-hover: #0e3766;
  --dropdownSwitcher-button-background-active: #06203f;
  --dropdownSwitcher-button-background-disable: #f3f3f3;
  --dropdownSwitcher-button-textAndIcon-default: #ffffff;
  --dropdownSwitcher-button-textAndIcon-hover: #ffffff;
  --dropdownSwitcher-button-textAndIcon-active: #ffffff;
  --dropdownSwitcher-button-textAndIcon-disable: #8ea1ae;
  --circleIconButton-background-default: transparent;
  --circleIconButton-background-hover: #f0f5f9;
  --circleIconButton-background-active: #cbd2d9;
  --circleIconButton-background-disable: transparent;
  --circleIconButton-border-default: transparent;
  --circleIconButton-border-hover: #f0f5f9;
  --circleIconButton-border-active: #cbd2d9;
  --circleIconButton-border-disable: transparent;
  --circleIconButton-textAndIcon-default: #52616b;
  --circleIconButton-textAndIcon-hover: #52616b;
  --circleIconButton-textAndIcon-active: #52616b;
  --circleIconButton-textAndIcon-disable: #8ea1ae;
  --accordionTabs-container-background-default: #f0f5f9;
  --accordionTabs-button-background-default: #f0f5f9;
  --accordionTabs-button-background-hover: #eaedf0;
  --accordionTabs-button-background-active: #144e91;
  --accordionTabs-button-border-default: #f0f5f9;
  --accordionTabs-button-border-hover: #eaedf0;
  --accordionTabs-button-border-active: #144e91;
  --accordionTabs-button-textAndIcon-default: #73828c;
  --accordionTabs-button-textAndIcon-hover: #73828c;
  --accordionTabs-button-textAndIcon-active: #ffffff;
  --hyperlink-color-default: #111111;
  --fields-border-focus: #25a9ef;
  --fields-badge-background-default: #25a9ef;
  --fields-badge-text-default: #ffffff;
  --dropdownList-background-odd: #f0f5f9;
  --dropdownList-background-even: #ffffff;
  --dropdownList-text-default: #73828c;
  --dropdownList-text-hover: #52616b;
  --dateAndTimePicker-background-active: #144e91;
  --dateAndTimePicker-background-hover: #f0f5f9;
  --dateAndTimePicker-background-disable: #ffffff;
  --dateAndTimePicker-background-today-unselected: #f0f5f9;
  --dateAndTimePicker-background-range-firstAndLast: #144e91;
  --dateAndTimePicker-background-range-between: #f0f5f9;
  --dateAndTimePicker-border-active: #144e91;
  --dateAndTimePicker-border-hover: #f0f5f9;
  --dateAndTimePicker-border-disable: #ffffff;
  --dateAndTimePicker-border-today-unselected: #144e91;
  --dateAndTimePicker-border-range-firstAndLast: #144e91;
  --dateAndTimePicker-border-range-between: #f0f5f9;
  --dateAndTimePicker-text-active: #ffffff;
  --dateAndTimePicker-text-hover: #52616b;
  --dateAndTimePicker-text-disable: #cbd2d9;
  --dateAndTimePicker-text-today-unselected: #52616b;
  --dateAndTimePicker-text-range-firstAndLast: #ffffff;
  --dateAndTimePicker-text-range-between: #52616b;
  --dateAndTimePicker-button-link: #0e3766;
  --tableResponsive-header-background-colspan: #ffffff;
  --tableResponsive-header-background-underColspan: #f0f5f9;
  --tableResponsive-header-textAndIcon-default: #73828c;
  --tableResponsive-body-background-odd: #f0f5f9;
  --tableResponsive-body-background-even: #ffffff;
  --tableResponsive-body-text-default: #52616b;
  --tableResponsive-body-text-notSpecifiedAndNone: #8ea1ae;
  --tableResponsive-body-icon-default: #73828c;
  --languageSwitcher-button-background-default: #f0f5f9;
  --languageSwitcher-button-background-hover: #f0f5f9;
  --languageSwitcher-button-background-active: #f0f5f9;
  --languageSwitcher-button-textAndIcon-default: #144e91;
  --languageSwitcher-button-textAndIcon-hover: #144e91;
  --languageSwitcher-button-textAndIcon-active: #144e91;
  --branchSwitcher-button-background-default: #ffffff;
  --branchSwitcher-button-background-hover: #f0f5f9;
  --branchSwitcher-button-border-default: #cbd2d9;
  --branchSwitcher-button-border-hover: #cbd2d9;
  --branchSwitcher-button-textAndIcon-default: #73828c;
  --branchSwitcher-button-textAndIcon-hover: #52616b;
  --tabs-background-active: #144e91;
  --tabs-background-inactive: #ffffff;
  --tabs-background-disable: #f3f3f3;
  --tabs-text-active: #ffffff;
  --tabs-text-inactive: #52616b;
  --tabs-text-disable: #8ea1ae;
  --tabs-notification-background-default: #cc2027;
  --tabs-notification-textAndIcon-default: #ffffff;
  --sidebar-background-default: #0e3766;
  --sidebar-separator-default: #ffffff;
  --sidebar-profile-background-default: #0e3766;
  --sidebar-profile-background-hover: #06203f;
  --sidebar-profile-background-active: #06203f;
  --sidebar-profile-text-default: #ffffff;
  --sidebar-profile-text-hover: #ffffff;
  --sidebar-profile-text-active: #ffffff;
  --sidebar-menu-background-default: #0e3766;
  --sidebar-menu-background-hover: #06203f;
  --sidebar-menu-background-active: #06203f;
  --sidebar-menu-textAndIcon-default: #ffffff;
  --sidebar-menu-textAndIcon-hover: #ffffff;
  --sidebar-menu-textAndIcon-active: #ffffff;
  --sidebar-submenu-background-default: #06203f;
  --sidebar-submenu-background-hover: #06203f;
  --sidebar-submenu-background-active: #06203f;
  --sidebar-submenu-textAndIcon-default: #ffffff;
  --sidebar-submenu-textAndIcon-hover: #25a9ef;
  --sidebar-submenu-textAndIcon-active: #25a9ef;
  --sidebar-notificationButton-background-default: #0e3766;
  --sidebar-notificationButton-background-hover: #06203f;
  --sidebar-notificationButton-background-active: #06203f;
  --sidebar-notificationButton-textAndIcon-default: #ffffff;
  --sidebar-notificationButton-textAndIcon-hover: #ffffff;
  --sidebar-notificationButton-textAndIcon-active: #ffffff;
  --sidebar-notificationBadge-background-default: #cc2027;
  --sidebar-notificationBadge-textAndIcon-default: #FFFFFF;
  --sidebar-companyNameText-default: #ffffff;
  --sidebar-appVersionText-default: #ffffff;
  --sidebar-avatar-background-default: #73828c;
  --sidebar-avatar-text-default: #ffffff;
  --pagination-background-default: #f0f5f9;
  --pagination-background-hover: #eaedf0;
  --pagination-background-active: #144e91;
  --pagination-background-disable: #f0f5f9;
  --pagination-border-default: #f0f5f9;
  --pagination-border-hover: #eaedf0;
  --pagination-border-active: #144e91;
  --pagination-border-disable: #f0f5f9;
  --pagination-textAndIcon-default: #73828c;
  --pagination-textAndIcon-hover: #73828c;
  --pagination-textAndIcon-active: #ffffff;
  --pagination-textAndIcon-disable: #cbd2d9;
  --popup-title-default: #144e91;
  --info-background-default: #f0f5f9;
  --info-text-default: #52616b;
  --loader-color: #144e91;
  --card-background-default: #f0f5f9;
  --card-background-odd: #f0f5f9;
  --card-background-even: #ffffff;
  --card-title-default: #144e91;
  --loginPage-background-start: #092A4F;
  --loginPage-background-end: #034079;
  --companyPage-cardList-background-odd: #F0F5F9;
  --companyPage-cardList-background-even: #ffffff;
  --companyPage-cardList-border-default: #CBD2D9;
  --companyPage-cardList-text-default: #52616b;
  --prefundPage-textWithBulletNumber-default: #52616B;
  --prefundPage-segmentedControl-background-active: #144E91;
  --prefundPage-segmentedControl-background-inactive: #ffffff;
  --prefundPage-segmentedControl-border-active: #144E91;
  --prefundPage-segmentedControl-border-inactive: #EAEDF0;
  --prefundPage-segmentedControl-text-active: #ffffff;
  --prefundPage-segmentedControl-text-inactive: #73828C;
  --prefundPage-listBank-logo-background-default: #ffffff;
  --prefundPage-listBank-logo-separator-default: #CBD2D9;
  --prefundPage-listBank-button-background-active: #0E3766;
  --prefundPage-listBank-button-background-inactive: #ffffff;
  --prefundPage-listBank-button-text-active: #ffffff;
  --prefundPage-listBank-button-text-inactive: #73828C;
  --dailyAttendancePage-chart-title-default: #52616B;
  --dailyAttendancePage-chart-slice-present: #144E91;
  --dailyAttendancePage-chart-slice-leave: #1D81B6;
  --dailyAttendancePage-chart-slice-notCheckIn: #25A9EF;
  --dailyAttendancePage-legend-bullet-present: #144E91;
  --dailyAttendancePage-legend-bullet-leave: #1D81B6;
  --dailyAttendancePage-legend-bullet-notCheckIn: #25A9EF;
  --dailyAttendancePage-legend-text-title: #52616B;
  --dailyAttendancePage-legend-text-description: #73828C;
  --profilePage-background-default: #144E91;
  --notificationPanel-usernameText-default: #144E91;
  --notificationPanel-usernameText-readed: #52616B;
  --notificationPanel-description-default: #52616B;
  --notificationPanel-description-readed: #73828C;
  --notificationPanel-time-default: #52616b;
  --notificationPanel-time-readed: #8ea1ae;
  --notificationPanel-newNotificationIndicator-default: #144E91;
}